<script>

export default {
	components: {
		Navbar: () => import('@/router/components/Navbar'),
		Footer: () => import('@/router/components/Footer'),
	},
  data() {
    return {

    };
  },
  created() {
    window.addEventListener("scroll", this.windowScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.windowScroll);
  },
  mounted() {

  },
  methods: {
    /**
     * Window scroll method
     */
    windowScroll() {
      const navbar = document.getElementById("navbar");
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    },
    /**
     * Toggle menu
     */
    toggleMenu() {
      document.getElementById("topnav-menu-content").classList.toggle("show");
    }
  }
};
</script>

<template>
  <div>
	  <Navbar :isHomepage="false"/>
    <div>
      <section class="section pt-4 bg-white mt-5">
        <div class="mt-5 mb-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="text-center mb-5">
                  <h4>Price and list of services</h4>
                  <h5>All prices do not include VAT. VAT will be added based on the country of residence of the customer.</h5>
                </div>
              </div>
            </div>
            <div>
              <div>
                Last updated October 3, 2021
              </div>
              <div>
                <h4>ACCOUNT</h4>
                <div class="row mt-1">
                  <div class="col text-left">
                    Deactivation
                  </div>
                  <div class="col text-right">
                    Free
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col text-left">
                    Restoration
                  </div>
                  <div class="col text-right">
                    15,- €
                  </div>
                </div>
              </div>
              <hr>
              <div>
                <h4>RESOURCES</h4>
                <div class="row mt-1">
                  <div class="col text-left">
                    Backup Recovery (Automated) (if applicable)
                  </div>
                  <div class="col text-right">
                    Free
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col text-left">
                    Backup Recovery (Manual)
                  </div>
                  <div class="col text-right">
                    50,- €
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col text-left">
                    Dedicated Instance IP (EU)
                  </div>
                  <div class="col text-right">
                    5,- € / monthly
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col text-left">
                    Dedicated Instance IP (NA)
                  </div>
                  <div class="col text-right">
                    8,- € / monthly
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col text-left">
                    Dedicated Instance IP (AS, OC)
                  </div>
                  <div class="col text-right">
                    10,- € / monthly
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col text-left">
                    Storage Add-On
                  </div>
                  <div class="col text-right">
                    1,50 € per GiB / monthly
                  </div>
                </div>
              </div>
              <hr>
              <div>
                <h4>SERVICE</h4>
                <div class="row mt-1">
                  <div class="col text-left">
                    Service Level Agreement (named engineer, phone support, contractual response times)
                  </div>
                  <div class="col text-right">
                    starting at 150,- € / monthly
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col text-left">
                    Live Support
                  </div>
                  <div class="col text-right">
                    50,- € / hourly
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col text-left">
                    Managed Service Pack Default
                  </div>
                  <div class="col text-right">
                    50,- € / monthly
                  </div>
                </div>
              </div>
              <hr>
              <div>
                <h4>PAYMENTS</h4>
                <div class="row mt-1">
                  <div class="col text-left">
                    Pay by manual bank transfer (EU)
                  </div>
                  <div class="col text-right">
                    1,00 € per transaction (network fees)
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col text-left">
                    Pay by manual bank transfer (US, ACH)
                  </div>
                  <div class="col text-right">
                    2,50 € per transaction (network fees)
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col text-left">
                    Pay by manual bank transfer (US, Wire Transfer)
                  </div>
                  <div class="col text-right">
                    10,00 € per transaction (network fees)
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col text-left">
                    Resolution of wrongful dispute (Stripe, SEPA)
                  </div>
                  <div class="col text-right">
                    10,- €
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col text-left">
                    Resolution of wrongful dispute (Stripe, Credit Card)
                  </div>
                  <div class="col text-right">
                    30,- €
                  </div>
                </div>
              </div>
              <hr>
              <div>
                <h4>ACCOUNT WALLET</h4>
                <div class="row mt-1">
                  <div class="col text-left">
                    Balance Withdrawal (PayPal)
                  </div>
                  <div class="col text-right">
                    5,- € + 1.0 % of total volume
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col text-left">
                    Balance Withdrawal (Bank Transfer, EU)
                  </div>
                  <div class="col text-right">
                    10,- € + 0.5 % of total volume
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col text-left">
                    Balance Withdrawal (Bank Transfer, International)
                  </div>
                  <div class="col text-right">
                    25,- € + 0.5 % of total volume
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col text-left">
                    Balance Withdrawal (Cryptocurrency)
                  </div>
                  <div class="col text-right">
                    5,- € + 0.5 % of total volume
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col text-left">
                    Facilitation Fee (90+ days of inactivity)
                  </div>
                  <div class="col text-right">
                    1.0 % of total wallet balance, min. 0.10 €
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Footer start -->
	    <Footer :isHomepage="false"/>
      <!-- Footer end -->
    </div>
  </div>
</template>